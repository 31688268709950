// LogoPage.js
import React, { useState } from 'react';
import './LogoPage.css'; // Import CSS file for styling

function LogoPage() {
  const [isGlowing, setIsGlowing] = useState(false);

  const handleMouseDown = () => {
    setIsGlowing(true);
  };

  const handleMouseUp = () => {
    setIsGlowing(false);
  };

  return (
    <div className="logo-page">
      <svg
        className={isGlowing ? 'logo glowing' : 'logo'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 612 1080" // Adjusted viewBox
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {/* SVG content */}
        <style>
          {`
            .st0{fill:#46F2D0;}
            .st1{fill:#FD0000;}
          `}
        </style>
        <path className="st0" d="M367.8,533.3c4.7,6.7,9.4,13.4,14.2,20c6.7,9.3,13.5,18.4,20.2,27.7c8.4,11.5,16.7,23.1,25.1,34.7
          c6.9,9.6,13.8,19.1,20.7,28.7c7.5,10.3,14.9,20.6,22.4,30.9c5.9,8.2,11.9,16.4,17.8,24.6c6.8,9.4,13.5,18.8,20.3,28.3
          c0.1,0.2,0.2,0.5,0.4,1c-0.8,0.1-1.5,0.2-2.2,0.2c-27.6,0-55.1,0-82.7,0c-1.5,0-2.4-0.3-3.4-1.7c-7.3-10.5-14.8-20.9-22.3-31.3
          c-9.9-13.8-19.8-27.5-29.7-41.3c-8.1-11.3-16.1-22.7-24.1-34c-10.5-14.8-21-29.5-31.6-44.3c-0.5-0.7-1.1-1.2-1.8-2
          c-1.6,2.1-3.3,4.4-4.9,6.6c-10.8,15.3-21.5,30.5-32.2,45.8c-12.3,17.6-24.6,35.1-36.9,52.7c-8,11.4-16.1,22.9-24.2,34.3
          c-3.2,4.6-6.4,9.2-9.8,13.7c-0.5,0.7-1.7,1.3-2.5,1.3c-28.1,0.1-56.3,0-84.4,0c-0.2,0-0.3-0.1-0.8-0.3
          c50.3-69.5,100.6-138.8,150.9-208.2c-4.8-6.6-9.6-13.2-14.5-20c-5.3-7.4-10.4-14.6-15.5-21.7c-3.9-5.4-7.9-10.8-11.8-16.1
          c-5-7.2-10-14.3-15.1-21.5c-5.8-8.2-11.7-16.3-17.6-24.4c-10.3-14.3-20.6-28.5-30.9-42.8c-10.8-15-21.7-30-32.5-45
          c-1.5-2.1-3.1-4.2-4.5-6.3c-0.3-0.4-0.3-1-0.4-1.7c1.2,0,2.3,0,3.3,0c26.7,0,53.4,0.1,80.2-0.1c2.4,0,3.7,1,4.9,2.7
          c9.9,13.6,19.8,27.3,29.7,40.9c8.1,11.3,16.2,22.6,24.3,33.8c9.4,13,19,25.8,28.5,38.8c6.3,8.6,12.5,17.3,18.7,26
          c0.3,0.4,0.8,0.8,1.3,1.2c9.2-13,18.3-25.9,27.5-38.8c12.1-16.9,24.3-33.8,36.4-50.7c8.1-11.3,16.1-22.7,24.1-34
          c4.4-6.2,8.8-12.5,13.3-18.6c0.5-0.6,1.5-1.2,2.3-1.2c26.8-0.1,53.6-0.1,81.2-0.1c-1.5,2.2-2.6,4.1-3.8,5.9
          c-7.6,10.4-15.2,20.9-22.8,31.3c-9.1,12.5-18.2,25-27.3,37.5c-6.6,9-13.1,18.1-19.6,27.1c-9.4,13-18.8,25.9-28.2,38.9
          c-0.3,0.4-0.4,0.8-0.9,1.4c-12.2,16.8-24.2,33.4-36.3,50.1c-0.9,1.2-1.8,2.3-2.4,3.5c-0.3,0.6-0.6,1.6-0.3,2.1
          c0.8,1.4,1.9,2.6,3,4.1C362.9,526.7,365.3,530,367.8,533.3z"/>
        <path className="st1" d="M257.3,519.3c-2.5,3.4-7.8,9.7-15.6,10.6c-9.1,1.1-15.9-5.7-25.6-15.2c-19.2-18.8-25.5-38.9-27.2-44.9
          c-1.3-4.6-2.1-8.6-2.6-11.5"/>
        <path className="st1" d="M366.9,519.3c2.5,3.4,7.8,9.7,15.6,10.6c9.1,1.1,15.9-5.7,25.6-15.2c19.2-18.8,25.5-38.9,27.2-44.9
          c1.3-4.6,2.1-8.6,2.6-11.5"/>
      </svg>
    </div>
  );
}

export default LogoPage;
