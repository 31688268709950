import './App.css';
import LogoPage from './pages/LogoPage'; // Adjusted import path


function App() {
  return (
    <div className="App">
    <LogoPage />

    </div>
  );
}

export default App;
